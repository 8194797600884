@charset "UTF-8";

@font-face {
  font-family: "gf";
  src:url("fonts/gf.eot");
  src:url("fonts/gf.eot?#iefix") format("embedded-opentype"),
    url("fonts/gf.woff") format("woff"),
    url("fonts/gf.ttf") format("truetype"),
    url("fonts/gf.svg#gf") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "gf" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "gf" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-iconconfirmacionpago:before {
  content: "\6c";
}
.icon-iconcontactformoutlined:before {
  content: "\6d";
}
.icon-icondetailoutlined:before {
  content: "\6e";
}
.icon-icondocumento:before {
  content: "\6f";
}
.icon-iconexito:before {
  content: "\70";
}
.icon-iconfilepdfoutlined:before {
  content: "\71";
}
.icon-iconforminoutlined:before {
  content: "\72";
}
.icon-iconformoutoutlined:before {
  content: "\73";
}
.icon-iconhomeoutlined:before {
  content: "\74";
}
.icon-iconimpuesto:before {
  content: "\75";
}
.icon-iconlandcoloroutlined:before {
  content: "\76";
}
.icon-iconliberacion:before {
  content: "\77";
}
.icon-iconordersoutlined:before {
  content: "\78";
}
.icon-iconpaycardoutlined:before {
  content: "\79";
}
.icon-iconpayphoneoutlined:before {
  content: "\7a";
}
.icon-iconpreformoutlined:before {
  content: "\41";
}
.icon-iconrevision:before {
  content: "\42";
}
.icon-iconsavemoneyoutlined:before {
  content: "\43";
}
.icon-iconshopping-caroutlined:before {
  content: "\44";
}
.icon-iconsitedeliveryoutlined:before {
  content: "\45";
}
.icon-icontiktokoutlined:before {
  content: "\46";
}
.icon-icontotaloutlined:before {
  content: "\47";
}
.icon-iconuseroutlined:before {
  content: "\48";
}
.icon-iconviewoutlined:before {
  content: "\49";
}
.icon-iconxoutlined:before {
  content: "\4a";
}
.icon-iconpayoutlined:before {
  content: "\4b";
}
.icon-iconconfirmacion:before {
  content: "\61";
}
.icon-iconclockoutlined:before {
  content: "\62";
}
.icon-iconcheckdeliveryoutlined:before {
  content: "\63";
}
.icon-iconchecklisttimeoutlined:before {
  content: "\64";
}
.icon-iconcalendartimeoutlined:before {
  content: "\65";
}
.icon-iconcalendaroutlined:before {
  content: "\66";
}
.icon-iconbetterpriceoutlined:before {
  content: "\67";
}
.icon-iconarchivooutlined:before {
  content: "\69";
}
.icon-iconaccountstateoutlined:before {
  content: "\6b";
}
.icon-iconarquitecto:before {
  content: "\68";
}
.icon-iconaprobacion:before {
  content: "\6a";
}
.icon-iconheartoutlined:before {
  content: "\50";
}
.icon-iconferrettioutlined:before {
  content: "\4f";
}
.icon-iconescalerabyn:before {
  content: "\4c";
}
.icon-iconmoon:before {
  content: "\4e";
}
.icon-iconsun:before {
  content: "\4d";
}
.icon-iconreporteresu:before {
  content: "\51";
}
.icon-iconmatrizclientes:before {
  content: "\52";
}
.icon-iconmantenimientotipofactor:before {
  content: "\53";
}
.icon-iconlicitudfondos:before {
  content: "\54";
}
.icon-iconfactores:before {
  content: "\55";
}
.icon-iconreporteuafe:before {
  content: "\56";
}
.icon-iconcliente-prospecto:before {
  content: "\57";
}
.icon-iconasesor-grupo:before {
  content: "\58";
}
.icon-icongrupo:before {
  content: "\59";
}
.icon-iconinformacion:before {
  content: "\5a";
}
.icon-iconmedios:before {
  content: "\30";
}
.icon-iconcontabilidad:before {
  content: "\31";
}
.icon-iconclientes:before {
  content: "\32";
}
.icon-iconasignar:before {
  content: "\33";
}
.icon-iconrefinanciacion:before {
  content: "\34";
}
.icon-iconsemaforo:before {
  content: "\35";
}
.icon-triangle-up:before {
  content: "\36";
}
.icon-x:before {
  content: "\37";
}
.icon-bank:before {
  content: "\38";
}
.icon-carryout:before {
  content: "\39";
}
.icon-filedone:before {
  content: "\21";
}
.icon-schedule:before {
  content: "\22";
}
.icon-issuesclose:before {
  content: "\23";
}
.icon-iconcambiodinero:before {
  content: "\24";
}
.icon-icontrabajo:before {
  content: "\25";
}
.icon-icontecnico:before {
  content: "\26";
}
.icon-iconordentecnico:before {
  content: "\27";
}
.icon-iconuploadfile:before {
  content: "\28";
}
.icon-iconreparacion:before {
  content: "\29";
}
.icon-iconherramientas:before {
  content: "\2a";
}
.icon-iconsalidainventario:before {
  content: "\2b";
}
.icon-iconproductreport:before {
  content: "\2c";
}
.icon-iconingresarinventario:before {
  content: "\2d";
}
.icon-iconmaterialconstruccion:before {
  content: "\2e";
}
.icon-iconbodega:before {
  content: "\2f";
}